import React from "react";
import { graphql, Link } from "gatsby";
import { DeepNonNullable } from "ts-essentials";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import { CareerPostDataQuery } from "../../../graphqlTypes";
import IBMFontUrl from "../../fonts/ibm-plex-serif-v10-latin-regular.woff2";
import Breadcrumb from "@components/Breadcrumb";
import BannerWrapper from "@ui/BannerWrapper";
import Button from "@ui/Button";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody, mdxExcerpt } from "@utils/mdxUtils";
import { CustomMarkdownComponents } from "@utils/markdownRendering";
import { JsonLd } from "gatsby-plugin-next-seo";

export const query = graphql`
  query CareerPostData($slug: String!) {
    strapiCareerspage {
      parentTitle: title
      childStrapiCareerspageBannerTitle {
        childMdx {
          body
        }
      }
      banner {
        title
        text
      }
    }
    allStrapiJobPosition(filter: { slug: { eq: $slug } }) {
      nodes {
        childStrapiJobPositionDescription {
          childMdx {
            body
            excerpt
          }
        }
        created_at
        title
        startDate
        type
        location
      }
    }
  }
`;

const CareerSpan = ({ title, text }: { title: string; text: string }) => (
  <div className="text-white flex flex-row gap-1">
    <h3 className="opacity-80">{title}:</h3>
    <h3>{text}</h3>
  </div>
);

const CareerPost = ({
  data,
}: {
  data: DeepNonNullable<CareerPostDataQuery>;
}) => {
  const { allStrapiJobPosition, strapiCareerspage } = data;

  const { nodes } = allStrapiJobPosition;
  const {
    title: jobTitle,
    childStrapiJobPositionDescription: description,
    startDate,
    created_at: createdAt,
    type,
    location,
  } = nodes[0];
  const {
    banner,
    childStrapiCareerspageBannerTitle: bannerTitle,
    parentTitle,
  } = strapiCareerspage;

  return (
    <Layout>
      <Seo title={jobTitle}>
        <link
          rel="preload"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
          href={IBMFontUrl}
        />
      </Seo>

      <JsonLd
        defer={false}
        json={{
          "@context": "https://schema.org",
          "@type": "JobPosting",
          description: mdxExcerpt(description),
          employmentType: type == "fulltime" ? "Full-time" : "Part-time",
          title: jobTitle,
          datePosted: createdAt,
          jobLocation: {
            address: location,
          },
          hiringOrganization: {
            name: "AccuRanker ApS",
          },
        }}
      />

      <div className="div-block flex flex-col gap-9 max-w-7xl mx-5 md:mx-auto px-4 xl:px-0 mt-4 mb-4">
        <Breadcrumb
          parents={[{ title: parentTitle, url: "/careers" }]}
          title={jobTitle}
        />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-4">
            <h1 className="text-white">{jobTitle}</h1>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row flex-wrap gap-4 sm:gap-28 mb-6">
          <CareerSpan
            title="Time"
            text={type == "fulltime" ? "Full-time" : "Part-time"}
          />
          <CareerSpan title="Location" text={location} />
          <CareerSpan title="Start Date" text={startDate} />
        </div>
      </div>
      <div className="full-width-strip bg-accuRankerCream">
        <div className="max-w-fit bg-accuRankerCream w-full py-12 mx-5 md:m-auto px-4 xl:px-0">
          <div className="max-w-7xl m-auto">
            <MDXRenderer components={CustomMarkdownComponents}>
              {mdxBody(description)}
            </MDXRenderer>
            <div className="w-full mt-10">
              <BannerWrapper>
                <div className="flex flex-col gap-6 text-white text-center justify-center items-center">
                  <h2 className="text-white">
                    <MDXRenderer>{mdxBody(bannerTitle)}</MDXRenderer>
                  </h2>
                  <p className="w-3/4">{banner.text}</p>
                  <Button color="orange">
                    <Link to="/contact/">Contact Us</Link>
                  </Button>
                </div>
              </BannerWrapper>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default CareerPost;
