import React from "react";

export const BannerWrapper: React.FC = ({ children }) => (
  <div className="mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl">
    <div className="relative rounded-3xl px-6 py-5 overflow-hidden shadow-xl sm:px-12 sm:py-8 bg-accuRankerPurple-500 banner-background">
      <div className="relative m-auto">{children}</div>
    </div>
  </div>
);

export default BannerWrapper;
