import React, { ReactNode } from "react";
import classNames from "classnames";
import MDXLink from "@components/MDXLink";

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  children: ReactNode;
  /** Main color of AccuRank */
  color?: "purple" | "orange" | "lightPurple" | "green" | "lightGray";
  /** If the button is disabled */
  disabled?: boolean;
  /** If the text in button should always be in one line */
  wrap?: boolean;
  /** If "link" is provided, we apply an MDXLink */
  link?: string;
};

const Button = ({
  children,
  color,
  disabled = false,
  wrap = false,
  link,
  className,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        `rounded-md px-4 py-2 text-white font-medium transition-colors duration-300 ${className}`,
        {
          "bg-accuRankerPurpleOpacity-dark hover:bg-accuRankerPurpleOpacity-light":
            color === "purple",
          "bg-accuRankerPurple-500 hover:bg-accuRankerPurple-400":
            color === "lightPurple",
          "bg-accuRankerOrange-500 hover:bg-accuRankerOrange-900":
            color === "orange",
          "bg-accuRankerGreen-500 hover:bg-accuRankerGreen-600":
            color === "green",
          "bg-white bg-opacity-20 hover:bg-opacity-10": color === "lightGray",
          "opacity-40 cursor-not-allowed": disabled,
          "whitespace-nowrap": wrap,
        }
      )}
      disabled={disabled}
      style={{ minHeight: "48px" }}
      {...props}
    >
      {link ? <MDXLink href={link}>{children}</MDXLink> : <>{children}</>}
    </button>
  );
};

export default Button;
